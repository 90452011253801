.fold {
  position: relative;

  h2:last-child,
  p:last-child {
    margin: 0;
  }

  // default color
  background-color: var(--col-bg);
  transition: background-color 0.5s; //moving between tabs, the color might change
  // see section.scss border
  &:after {
    left: 0;
    right: 0;
  }

  // other colors
  &.active {
    background-color: var(--legacy-col-active);
  }
  &.progress {
    background-color: var(--legacy-col-progress);
  }
  &.negative {
    background-color: var(--legacy-col-negative);
  }
  &.positive {
    background-color: var(--legacy-col-positive);
  }

  // in colored fold bars the text color should be contrasting white
  &.active,
  &.progress,
  &.negative,
  &.positive {
    color: rgba(white, 0.7);
    h2,
    h4 {
      color: white;
    }
    h3 {
      color: rgba(white, 0.7);
    }
    a:not(:hover) {
      color: rgba(white, 0.7);
    }

    avatar .labels {
      .text-name,
      .text-label {
        color: white !important;
      }
    }
  }
}
