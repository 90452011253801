@use 'variables' as *;
@use '../common/text-styles' as *;

$border-left-width: 4px;

.list-item {
  border-radius: 0;
  border: none;
  display: flex;
  align-items: center; //vertical
  flex-wrap: nowrap;
  outline: none;
  position: relative;
  min-height: var(--legacy-item-height);
  //height: $item-height; //IE flex vertical align issue
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: (20px)-$border-left-width;
  padding-right: 20px;
  margin-bottom: 0;
  background-color: transparent;
  border-left: $border-left-width solid transparent;
  border-bottom: 1px solid var(--legacy-col-lines);

  &.clickable {
    cursor: pointer;
    transition:
      0.3s background-color,
      0.3s color,
      border-left-color 0.3s;

    &:hover,
    &.active:hover,
    &.active {
      background-color: var(--col-active-bg);
      color: var(--legacy-col-active);
      &.active {
        border-left: $border-left-width solid var(--legacy-col-active);
      }
    }

    &.disabled {
      pointer-events: none;
      color: var(--col-text-muted);
    }
  }

  &.multiline {
    height: auto;
  }

  span.label-grow {
    flex: 1 1 auto;
  }

  span.labels {
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-right: 0;
    line-height: 1.5;
  }

  span.text:not(.negative) {
    flex-basis: 50%;
  }

  &.compact {
    padding-left: (15px)-$border-left-width;
    span.labels {
      padding: 0 15px;
    }
  }

  .count {
    flex: 0 0 auto;
    color: var(--col-text-secondary);
    font-size: smaller;
    min-width: 26px;
    text-align: center;
    margin-right: -10px;
  }

  rcp-skill-level-detail,
  rcp-skill-score-level-badge {
    // prevent squishing
    flex-basis: auto;
    margin-right: 10px;
  }

  .badge.pull-right {
    position: absolute;
    right: 10px;
  }
}

.list-items-ellipsis .list-item span.labels {
  @extend .ellipsis;
}

// list item activated by parent
.active > .list-item {
  background-color: var(--col-active-bg);
  color: var(--legacy-col-active);
  border-left: $border-left-width solid var(--legacy-col-active);
}

// divider
.list-divider {
  position: relative;
  color: var(--col-text-secondary);
  padding: 8px var(--legacy-default-padding);
  min-height: 40px;
  display: flex;
  align-items: center;

  letter-spacing: 1px;
  font-size: 0.9em;
  text-transform: uppercase;
}

// match default padding if sidebar nav
.view-sidebar .list-divider {
  padding-left: var(--legacy-default-padding);

  // align icons
  fa-icon {
    margin-right: 5px;
    margin-left: -20px;
  }
}
