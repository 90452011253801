@use 'sass:math';
@use './mixins/media' as *;

@mixin cover-full($z-index: 9999) {
  position: fixed;
  z-index: $z-index;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// general purpose grid
// using flex instead of CSS grid for IE compatibility
@mixin grid($columns: 1, $gap: 10px) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  // margin: -$gap;

  > * {
    flex-basis: math.div(100%, $columns);
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
    // padding: $gap;
    // rather than giving the cell padding, we give margin to the cell's child
    // otherwise, IE will not include it in the total flex-basis width and mess up the columns
    > * {
      margin: $gap;
    }
  }
}

@mixin static-grid($col-width: 80px, $col-height: 80px, $max-cols: 10, $gap: 10px) {
  @include grid(1, $gap);
  & {
    width: $max-cols * $col-width + 1; /* pour one out for IE (seriously, IE needs one extra pixel to show 7 days per row)*/

    > * {
      flex-basis: $col-width;
      height: $col-height;
    }
  }
}

@mixin responsive-grid($gap: 10px) {
  @include grid(1, $gap);

  // make responsive
  @include media-small-up {
    > * {
      flex-basis: 50%;
      &.grid-cell-double {
        flex-basis: 100%;
      }
    }
  }

  &:not(.max-cols-2) {
    @include media-medium-up {
      > * {
        flex-basis: 33%;
        &.grid-cell-double {
          flex-basis: 66%;
        }
      }
    }

    @include media-large {
      > * {
        flex-basis: 25%;
        &.grid-cell-double {
          flex-basis: 50%;
        }
      }
    }
  }
}
