@use 'mixins' as *;

// things posing as image
.image {
  background-repeat: no-repeat;
  background-size: cover; //usually
  background-position: center;
}

.image-thumbnail {
  @include sharp-shadow;
  & {
    position: relative;
    background-color: white;
    background-size: cover;
    border-radius: var(--legacy-br);
    width: 100px;
    height: 100px;
    overflow: hidden;

    &.small {
      width: 40px;
      height: 40px;
    }
  }
}

img.graphic {
  margin: 0 auto;
  display: block;
  height: 150px;
}
