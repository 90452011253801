@use 'mixins' as *;

.flex {
  display: flex;
  width: 100%;

  &.align-center {
    align-items: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-gap {
    gap: var(--legacy-default-element-spacing-s);
  }

  &.flex-column {
    flex-direction: column;
  }

  // equal columns that collapse on smaller screens
  &.flex-equal-columns {
    column-gap: 10px;
    justify-content: space-between;

    .flex-col {
      flex: 1 1 auto;
      width: 0; //forces equal size columns
    }

    @include media-small-down() {
      flex-wrap: wrap;
      row-gap: var(--legacy-default-padding);
      .flex-col {
        width: 100%;
      }
    }
  }

  &.flex-grow {
    flex-grow: 1;
  }

  &.flex-shrink {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
  }

  &.flex-gap-default {
    gap: var(--legacy-default-element-spacing);
  }

  &.flex-end {
    margin-left: auto;

    &.nowrap {
      white-space: nowrap;
    }
  }
}
