// icons
fa-icon {
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;

  // if before text, no margin left
  &:first-child {
    margin-left: 0;
  }
  // if after text, no margin right
  &:last-child {
    margin-right: 0;
  }
}

.icon-style-angle {
  font-size: 0.8em;
}

:not(:hover):not(.active) > .icon-style-angle {
  color: var(--col-text-tertiary);
}
rcp-dropdown-menu-item:hover .icon-style-angle,
rcp-button:hover .icon-style-angle {
  color: white !important;
}

fa-layers fa-icon {
  margin-right: 0;
}
