.overlay {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(black, 0.7);

  transition:
    opacity 0.8s,
    display 0.8s;
}
