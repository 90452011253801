body {
  font-family: var(--font-default);
  font-size: var(--font-size-normal);
  color: var(--col-text-primary);
}

.active {
  color: var(--legacy-col-active);
}

.text {
  // colors
  &.sky-blue {
    color: var(--legacy-sky-blue);
  }
  &.blue {
    color: var(--legacy-blue);
  }
  &.yellow,
  &.progress {
    color: var(--legacy-yellow);
  }
  &.orange {
    color: var(--legacy-orange);
  }
  &.green {
    color: var(--legacy-green);
  }
  &.negative {
    color: var(--legacy-col-negative);
  }
  &.positive {
    color: var(--legacy-col-positive); // !important;
  }
  &.alert {
    color: var(--col-alert);
  }
  &.muted {
    color: var(--col-text-muted); // !important;
  }
  &.secondary {
    color: var(--col-text-secondary);
  }
  &.neutral {
    color: var(--legacy-gray);
  }
  &.normal {
    font-weight: normal;
  }
  &.bold {
    font-weight: bold;
  }
  &.cursive {
    font-style: italic;
  }
  &.dotted {
    text-decoration: underline dotted;
  }

  &.italic {
    font-style: italic;
  }

  &.smaller {
    font-size: var(--font-size-small);
  }

  &.tertiary {
    color: var(--col-text-tertiary);
  }

  &.max-2-lines {
    /* note: this is currently supported in all supported browsers */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: break-spaces !important; /* important to override tabel styles */
  }
}

.clickable {
  cursor: pointer;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-titles);
  margin-top: 0;
  margin-bottom: var(--legacy-default-element-spacing);
  color: var(--col-text-primary);

  &.underline {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--legacy-col-lines);
  }
  &.clickable {
    outline: none !important;
    transition: color 0.2s;
    &:hover {
      color: var(--col-accent);
      cursor: pointer;
    }
  }

  .badge {
    position: relative;
    top: -3px;
  }
}

h1 {
  font-size: var(--font-size-h1);
  line-height: 1.2em;
  font-weight: normal;
  color: var(--col-text-headers);
  margin-bottom: calc(var(--legacy-default-element-spacing) * 1.5);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: 1.2em;
  font-weight: normal;
  margin-bottom: calc(var(--legacy-default-element-spacing) * 1.5);
}
rcp-modal-window h2 {
  font-size: 1.2em;
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  margin-bottom: var(--legacy-default-element-spacing);
}

.h-description {
  margin-bottom: var(--legacy-default-spacing);
}

h3 + .h-description {
  margin-top: calc(-0.5 * var(--legacy-default-element-spacing));
  margin-bottom: calc(1.4 * var(--legacy-default-element-spacing));
}

h4 {
  font-size: var(--font-size-normal);
  text-transform: uppercase;
  color: var(--col-text-secondary);
  letter-spacing: 1px;
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: normal;
  margin-bottom: calc(1.2 * var(--legacy-default-element-spacing));
}

p {
  margin-top: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap {
  white-space: normal;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.logo {
  color: var(--col-accent);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.caps {
  text-transform: uppercase;
}

a,
a:focus {
  color: var(--col-accent);

  &.pull-right {
    margin-left: 15px;
  }
}
a:hover {
  color: var(--legacy-col-active);
  text-decoration: none;
}

a.small {
  color: var(--col-text-muted);
  opacity: 0.5;
  font-size: smaller;

  &:hover,
  &:focus {
    color: var(--legacy-col-active);
    text-decoration: none;
  }
}

.strike {
  text-decoration: line-through;
}

// lists
ul {
  padding-left: 1.5em;
}

div.info-box,
p.info-box,
info-box {
  max-width: 100%;
  border: none;
  background-color: var(--legacy-col-bg-info-box);
  color: var(--col-text-primary);
  padding: var(--legacy-default-element-spacing);
  border-radius: var(--legacy-br);

  p:last-child,
  .form-row:last-child,
  ol:last-child,
  ul:last-child {
    margin-bottom: 0;
  }

  &.info-box-flex {
    display: flex;
    align-items: center;
  }

  &.clickable {
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      color: white;
      background-color: var(--col-active-bg);
    }
  }

  &.alert {
    background-color: var(--col-alert-bg);
    color: var(--col-alert-dark);
    &.clickable:hover {
      background-color: var(--col-alert);
    }
  }

  &.positive {
    background-color: var(--col-positive-bg);
    color: var(--col-positive-dark);
    &.clickable:hover {
      background-color: var(--legacy-col-positive);
    }
  }

  &.negative {
    background-color: var(--col-negative-bg);
    color: var(--col-negative-dark);
    &.clickable:hover {
      background-color: var(--legacy-col-negative);
    }
  }

  &.info {
    background-color: var(--col-primary-bg);
    color: var(--col-primary-dark);
    &.clickable:hover {
      background-color: var(--legacy-col-primary);
    }
  }

  &.inactive {
    background-color: var(--col-bg-darker);
    color: var(--col-bg);
  }

  button {
    margin-bottom: 0;
  }

  fa-icon {
    font-size: 1.2em;
    padding-right: 5px;
  }
}

.pre-line {
  white-space: pre-line;
}

p.larger,
span.larger,
div.larger {
  font-size: larger;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// flex classes
.text-flex {
  display: flex;
  flex-wrap: wrap;

  &.text-flex-column {
    flex-direction: column;
  }

  > * {
    flex-grow: 1;
  }
}
