// add styling to root element
// so we can override in wrapping elements
:root {
  --after-section-border-display: block;
}

section {
  position: relative;
  padding: var(--legacy-default-padding);

  &:after {
    content: '';
    display: var(--section-after-border-display);
    border-bottom: 1px solid var(--legacy-col-lines);
    position: absolute;
    bottom: 0;
    left: var(--legacy-default-padding);
    right: var(--legacy-default-padding);
  }

  header {
    font-weight: bold;
    margin-bottom: calc(1.5 * var(--legacy-default-element-spacing));

    &.half-mb {
      margin-bottom: var(--legacy-default-element-spacing);
    }

    &.flex {
      display: flex;
      align-items: center;
    }

    &.larger {
      font-size: larger;
      font-weight: normal;
    }

    fa-icon {
      margin-right: 10px;
    }

    button {
      font-weight: initial;
    }
  }

  &.no-padding-top {
    padding-top: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.no-border {
    border: none;
    &:after {
      display: none;
    }
  }

  &.no-padding {
    padding: 0;
    > header {
      padding: var(--legacy-default-padding);
      margin: 0;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  // section as info box
  &.alert {
    background-color: var(--col-alert-bg);
    color: var(--col-alert-dark);
    &:after {
      display: none;
    }
  }

  &.positive {
    background-color: var(--col-positive-bg);
    color: var(--col-positive-dark);
    &:after {
      display: none;
    }
  }

  &.negative {
    background-color: var(--col-negative-bg);
    color: var(--col-negative-dark);
    &:after {
      display: none;
    }
  }

  &.info {
    background-color: var(--col-primary-bg);
    color: var(--col-primary-dark);
    &:after {
      display: none;
    }
  }

  // section fold
  &.section-fold {
    text-align: center;
  }
}

@media screen and (min-width: 1850px) {
  .section-row {
    > * {
      min-width: 33%; // make sure section resizes and wraps > 3 columns; this number is kind of arbitrary to show max 3 columns. Currently there are not very many use cases (> 3 columns can happen in goals modal)
    }
  }
}

// container
.section-row {
  display: flex;
  flex-wrap: wrap;

  // full width for the row means the childer get all the space
  width: 100%;

  > * {
    flex: 1 0 0;
  }

  // make the sections flex too, so that it's content
  // can be filled out vertically and both section contents get the same height (e.g. two tiles next to each other)
  &.section-row-flex-children {
    > section {
      display: flex;
      flex-direction: column;
      > * {
        flex: 1 0 auto;
      }
      header {
        flex: 0 0 auto;
      }
    }
  }

  &.gap {
    gap: var(--legacy-default-padding);
  }

  // when there is a section inside the section row, remove padding left so the alignment looks nice.
  section {
    header {
      padding: 0;
      margin-bottom: var(--legacy-default-padding);
    }
  }
}

// no padding and smaller header padding in subnav
.view-sidebar {
  section:not(.padded),
  section[section-expandable]:not(.padded) {
    padding: 0;
    margin-bottom: var(--legacy-default-element-spacing);
    header {
      padding: 20px;
      margin: 0;
    }

    // nested
    section {
      margin-bottom: 0;
      padding-left: 20px;
    }
  }
  section.no-padding-top {
    padding-top: 0 !important;
  }
}

// fold in advanced modal loses top padding because it has a title bar above it
rcp-modal-window.advanced section.section-fold,
rcp-modal-window.simple section.section-fold {
  padding-top: 0;
}

// section in section styling
section section {
  border-radius: var(--legacy-br);
  border: 1px solid var(--legacy-col-lines);
  margin-bottom: var(--legacy-default-element-spacing);

  &:after {
    display: none !important;
  }

  // third level section in section
  section {
    border: none;
    padding: 0;
    margin-bottom: var(--legacy-default-padding);
    header {
      color: var(--col-text-secondary);
    }
  }
}

.section-divider {
  display: flex;
  // need to use padding instead of parent flex gap, because of lastpass
  // which adds an element that ruins the gap
  padding-top: var(--section-spacing-between);

  &:before,
  &:after {
    content: '';
    flex: 1;
    border-bottom: 2px solid var(--legacy-col-lines);
    margin: auto;
  }

  &:before {
    margin-right: var(--spacing-md);
  }

  &:after {
    margin-left: var(--spacing-md);
  }
}
