.rich-text {
  color: var(--col-text-primary);
  font-size: var(--font-size-normal);
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // unset all inherited h1 styles
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: unset;
    font-weight: unset;
    font-style: unset;
    font-size: unset;
    color: unset;
    margin: 0;
    padding: 0;
  }

  .h1-container {
    padding: var(--legacy-default-padding);
    h1 {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 2em;
    margin-bottom: 0.8em;
    color: var(--col-primary-dark);
    position: relative;

    fa-icon.icon-expand {
      position: absolute;
      left: -24px;
      display: inline-block;
      // margin-right: 10px;
      transform: rotate(0deg);
      transition: transform 0.3s;
    }

    &.h1-collapsable {
      font-size: 1.5em;
      cursor: pointer;
      &:hover {
        color: var(--legacy-col-active);
      }
    }
    &.h1-expanded {
      fa-icon.icon-expand {
        transform: rotate(90deg);
      }
    }
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.8em;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--legacy-col-lines);
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  h3 {
    font-size: 1.2em;
    margin-bottom: 0.8em;
    margin-top: 1.5em;
  }

  p {
    margin-bottom: 0.8em;
  }

  ul,
  ol {
    padding-left: 2em;
  }

  blockquote {
    margin-left: 1.3em;
    padding-left: 1em;
    border-left: 4px solid var(--legacy-gray);
  }

  // special mentions
  .mention,
  .internal-link {
    padding: 2px 2px;
    border-radius: var(--legacy-br);
    display: inline;
  }

  .mention {
    background-color: var(--col-primary-bg);
    color: var(--legacy-col-primary);
    font-weight: bold;

    // we can choose to hide the denotation character (old mention types)
    .ql-mention-denotation-char {
      display: none;
    }
  }

  .internal-link {
    background-color: var(--col-positive-bg);
    color: var(--legacy-col-positive);
  }

  // Some things change in the 'live' end user display
  &.rich-text-live {
    .mention,
    .internal-link {
      transition:
        background-color 0.3s,
        color 0.3s;
      cursor: pointer;

      &:hover {
        background-color: var(--legacy-col-primary);
        color: white;
      }
    }

    .internal-link {
      &:hover {
        background-color: var(--legacy-col-positive);
      }
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid var(--legacy-col-lines);
      padding: 6px 10px;
      background-color: white;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    tr:nth-child(odd) td {
      background-color: #fafafa;
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  // wrapper for images
  .image-section {
    margin-top: 30px;
    margin-bottom: 30px;

    position: relative;

    &.ProseMirror-selectednode {
      border-radius: 2px;
      border-color: var(--legacy-col-lines);
      border-width: 2px;
    }
  }

  // image styling
  img {
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;
    display: block;
    border-radius: 2px;
    border: 1px solid var(--legacy-col-lines);
  }
}

// global style for editor for things that don't work in component styling
.editor {
  // show placeholder text
  .ProseMirror p.is-editor-empty:first-child::before {
    position: absolute;
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 30px;
    width: 50%;
    pointer-events: none;
    border-bottom: 1px solid var(--legacy-col-lines);
  }
}
