@use 'mixins' as *;

.label {
  $label-padding-h: 9px;
  $label-height: 30px;

  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  height: $label-height;
  line-height: var(--font-size-normal);
  font-size: var(--font-size-normal);

  padding: 0 $label-padding-h;
  border-radius: var(--legacy-br);

  @include defaultColors();
  @include basicColors();
  &.clickable {
    @include hoverColors();
  }

  & {
    &.small {
      font-size: calc(0.8 * var(--font-size-normal));
      height: calc(0.8 * $label-height);
      padding: calc(0.6 * $label-padding-h);
    }

    &.spaced {
      margin-bottom: 5px;
      margin-right: 5px;
    }

    &.detailed {
      overflow: hidden;

      &.right {
        padding-right: 0;
        .detail {
          margin-left: $label-padding-h;
          margin-right: 0;
        }
      }
      &:not(.right) {
        padding-left: 0;
        .detail {
          margin-right: $label-padding-h;
          margin-left: 0;
        }
      }

      .detail {
        background: rgba(black, 0.03);
        align-self: stretch;
        height: auto;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 $label-padding-h;
      }

      avatar.detail {
        padding: 0 2px;
      }
    }

    &.floating {
      position: absolute;
      left: 0;
      top: 0;
      &.right {
        left: auto;
        right: 0;
      }
    }
  }
}

.filter-label-container {
  display: inline-flex;
  gap: var(--spacing-md);
}
