@mixin media-tiny-up() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-tiny() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-small() {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin media-small-up() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-small-down() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin media-medium() {
  @media (min-width: 1025px) and (max-width: 1499px) {
    @content;
  }
}

@mixin media-medium-up() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin media-medium-down() {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin media-large() {
  @media (min-width: 1500px) {
    @content;
  }
}
