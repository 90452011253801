.overlay-footer {
  ul {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: inline;
      color: var(--col-text-secondary);
      font-size: var(--font-size-small);
      padding-right: 5px;

      &:hover {
        color: var(--legacy-col-active);
      }

      &:not(:last-child):after {
        content: '\00b7';
        padding-left: 5px;
        color: var(--col-text-secondary);
      }
    }
  }
}
