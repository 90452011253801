// Animate drag and drop
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-preview {
  box-sizing: border-box;
  transform: rotate(10deg);
  transition: none !important;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
