/* Use for web apps */

:root {
  --font-default: 'Source Sans Pro', Arial, sans-serif;
  --font-titles: 'Source Sans Pro', Arial, sans-serif;

  /* TODO: these will be replaced when the Inter font is applied */
  --font-size-small: 13px;
  --font-size-normal: 16px;
  --font-size-h1: 26px;
  --font-size-h2: 26px;
  --font-size-h3: 22px;
  --font-size-h5: 20px;

  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-700: 700;

  --app-font-family: Lato, Arial, sans-serif;

  /*use for page and modal headers, especially in folds*/
  --app-font-size-titles: 20px;

  /*use for section and list headers*/
  --app-font-size-headers: 17px;
  --app-font-group-headers: 18px;

  /*use for paragraph text, buttons etc*/
  --app-font-size-primary: 17px;

  /*use for smaller text e.g. subtitles on tiles*/
  --app-font-size-secondary: 15px;

  /*use for very small text, e.g. small labels on buttons with large icons*/
  /*dont go smaller than this*/
  --app-font-size-tertiary: 13px;

  --font-size-huge: 48px;
  /* TODO: turn these on when applying the new Inter font */
  /*--font-size-normal: 14px;*/
  /*--font-size-small: 12px;*/
  --font-size-tiny: 10px;
  --font-size-header-1: 20px;
  --font-size-header-2: 18px;
  --font-size-icon-small: 14px;
  --font-size-icon-normal: 16px;
  --font-size-icon-badge-xs: 12px;
  --font-size-icon-badge-sm: 16px;
  --font-size-icon-badge-md: 24px;
  --font-size-icon-badge-lg: 40px;
  --font-size-text-badge-sm: 12px;
  --font-size-text-badge-md: 16px;
  --font-size-text-badge-lg: 24px;

  --font-weight-normal: var(--font-weight-400);
  --font-weight-semi-bold: var(--font-weight-600);
  --font-weight-bold: var(--font-weight-700);
}
