@use 'mixins' as *;

.show-tiny {
  @include media-tiny-up() {
    display: none !important;
  }
}

.hide-tiny {
  @include media-tiny() {
    display: none !important;
  }
}

.hide-small {
  @include media-small-down() {
    display: none !important;
  }
}

.show-small {
  @include media-medium-up() {
    display: none;
  }
}

.hide-medium {
  @include media-medium-down() {
    display: none !important;
  }
}

.full-width-tiny {
  @include media-tiny() {
    min-width: 100% !important;
  }
}

.full-width-small {
  @include media-small-down() {
    min-width: 100% !important;
  }
}

.full-width-medium {
  @include media-medium-down() {
    min-width: 100% !important;
  }
}
