@use 'mixins' as *;

ul.nav-tabs {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  .nav-item {
    min-width: 150px;
    text-align: center;
  }
}

// minimal styling
ul.nav-tabs.nav-tabs-minimal {
  border-bottom: 1px solid var(--legacy-col-lines);

  .nav-item {
    .nav-link {
      display: block;
      padding: 15px 30px;
      color: var(--col-text-secondary);
      font-weight: bold;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      text-decoration: none;
      transition: border-bottom-color 0.3s;

      &.active,
      &:hover {
        color: var(--col-text-primary);
        border-bottom: 2px solid var(--legacy-col-primary);
      }
    }
  }
}

ul.nav-breadcrumbs {
  border-bottom: 1px solid var(--legacy-col-lines);
  background-color: var(--col-bg);
  height: var(--legacy-item-height);

  margin: 0;
  display: flex;
  list-style: none;
  padding: 0;

  @include media-small-down {
    display: none;
  }

  & {
    .nav-item {
      min-width: 100px;
      text-align: center;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .nav-link {
        display: block;
        position: relative;
        padding: 12px var(--legacy-default-padding);
        color: var(--col-text-secondary);
        cursor: pointer;
        text-decoration: none;
        transition: background-color 0.3s;

        &.active,
        &:hover {
          color: var(--col-text-primary);
        }

        fa-icon.icon-angle-right {
          position: absolute;
          right: 0;
          top: 13px;
        }
      }
    }
  }
}

// fill out full width
.view-sidebar .nav-breadcrumbs .nav-item {
  fa-icon {
    margin-right: 10px;
  }
}
