@use './variables/scss/colors' as *;

$colors-list: (
  'blue',
  'sky-blue',
  'green',
  'yellow',
  'orange',
  'red',
  'primary',
  'positive',
  'negative',
  'progress',
  'active',
  'gaia',
  'purple'
);
$colors-list-vars: (
  $legacy-blue,
  $legacy-sky-blue,
  $legacy-green,
  $legacy-yellow,
  $legacy-orange,
  $legacy-red,
  $legacy-col-primary,
  $legacy-col-positive,
  $legacy-col-negative,
  $legacy-col-progress,
  $legacy-col-active,
  $legacy-gaia,
  $legacy-purple
);

@function color-lighter($color) {
  @return scale-color($color, $lightness: 10%);
}
@function color-darker($color) {
  @return scale-color($color, $lightness: -10%);
}
@function contrasting-text-color($accent-color, $background-color: $accent-color) {
  // on a dark (or colorful) background, display white text
  @if lightness($background-color) < 80% {
    @return white;
  } @else {
    // on a light background, prefer the accent color if it's not too light
    @if lightness($accent-color) < 80% {
      @return $accent-color;
    } @else {
      // otherwise use primary text color
      @return $col-text-primary;
    }
  }
}

// apply default colors
@mixin color-default($color) {
  &:not(.transparent) {
    background-color: $color;
    color: contrasting-text-color($color);
  }
  // exception for transparent buttons: use contrasting on white. The hover state is the same.
  &.transparent {
    color: contrasting-text-color($color, white);
  }
}

// apply a 'basic' color set with colored border and text
@mixin color-basic($color, $text-color: $color) {
  border: 1px solid $color;
  background-color: white;
  color: contrasting-text-color($color, white);
}

@mixin color-hover($color) {
  background-color: color-darker($color) !important;
  color: contrasting-text-color($color) !important;
}

// batch add default styles for colors
@mixin defaultColors() {
  // apply default color (no color class)
  // except on .basic elements, otherwise we would have to unset properties
  &:not(.basic) {
    @include color-default($legacy-col-lines);

    $i: 1;
    //use the position of colors-list to grab the equivalent color value
    @each $item in $colors-list {
      &.#{$item} {
        $color: nth($colors-list-vars, $i);
        @include color-default($color);
      }
      $i: $i + 1;
    }
  }
}

// batch add .basic styles for colors
@mixin basicColors() {
  // apply default color (no color class)
  &.basic {
    @include color-basic($legacy-col-lines, $col-text-secondary);
  }

  //use the position of colors-list to grab the equivalent color value
  $i: 1;
  @each $item in $colors-list {
    &.basic.#{$item} {
      $color: nth($colors-list-vars, $i);
      @include color-basic($color);
    }
    $i: $i + 1;
  }
}

// batch add :hover styles for colors
@mixin hoverColors() {
  // apply default hover color (no color class)
  &:hover {
    @include color-hover($legacy-blue);
    & {
      .muted {
        color: inherit;
      }
    }
  }

  // additional selected definition
  // so that on a non-colored button, the selected color will still be active
  &.selected {
    background-color: var(--legacy-col-active);
    border-color: var(--legacy-col-active);
    color: white;
  }

  //use the position of colors-list to grab the equivalent color value
  $i: 1;
  @each $item in $colors-list {
    &.#{$item}:hover {
      $color: nth($colors-list-vars, $i);
      @include color-hover($color);
    }
    $i: $i + 1;
  }
}

// batch add .selected styles for colors
// uses :hover colors
@mixin selectedColors() {
  // additional selected definition
  // so that on a non-colored button, the selected color will still be active
  &.selected {
    @include color-hover($legacy-col-active);
  }

  //use the position of colors-list to grab the equivalent color value
  $i: 1;
  @each $item in $colors-list {
    &.#{$item}.selected {
      $color: nth($colors-list-vars, $i);
      @include color-hover($color);
    }
    $i: $i + 1;
  }
}
