@use '../css-variables' as *;
@use '../variables' as *;
@use '../mixins' as *;
@use '../media' as *;
@use '../rich-text' as *;

@use 'fonts' as *;

@use './common/layout' as *;
@use './common/loading-splash' as *;
@use './common/loading' as *;
@use './common/text-styles' as *;
@use './common/icons' as *;
@use './common/misc' as *;
@use './common/printable' as *;
@use './common/flex' as *;

@use './elements/image' as *;
@use './elements/person' as *;
@use './elements/tabs' as *;
@use './elements/list' as *;
@use './elements/table' as *;
@use './elements/forms' as *;
@use './elements/buttons' as *;
@use './elements/overlay' as *;
@use './elements/tile' as *;
@use './elements/fold' as *;
@use './elements/label' as *;
@use './elements/badge' as *;

// newly added
@use './elements/drag-drop' as *;
@use './elements/modals' as *;
@use './elements/section' as *;

// shared feates
@use './shared/support-center' as *;

// has to be last, to correctly override global classes
@use './mobile' as *;

// Custom themes for tippy
@use 'tooltip/default';

// global turn off for padding
.no-padding {
  padding: 0 !important;
}

.not--restyle_ft--DUMMY {
  body {
    border: 10px solid coral;
  }
}
