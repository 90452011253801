@use 'mixins' as *;

html {
  height: 100vh;
}

body {
  height: inherit;

  margin: 0;
  background-color: var(--legacy-col-bg-main);

  p {
    margin-bottom: var(--legacy-default-element-spacing);
  }

  // prevent scrolling when an overlay is shown
  // needs to be solved differently, because there are components that have the overlay inside the body at all times, but hidden at a higher level
  //&:has(.overlay) {
  //  overflow: hidden;
  //}
}

main {
  display: grid;
  grid-template-rows: var(--legacy-navbar-height) auto;
  // without minmax(0, auto), the main content will not shrink when the window is made smaller
  grid-template-columns: var(--legacy-navbar-width) minmax(0, auto);
  grid-template-areas:
    'nav nav'
    'side main';

  // needed so the sidebar height does not change when dynamic content is reloaded
  height: 100vh;

  // when the sidebar changes size, this animates the main content
  transition: grid-template-columns 0.3s;

  &:has(.sidebar-expanded) {
    // without minmax(0, auto), the main content will not shrink when the window is made smaller
    grid-template-columns: var(--legacy-navbar-width-expanded) minmax(0, auto);
  }

  &:has(.sidebar-expanded.hidden) {
    // without minmax(0, auto), the main content will not shrink when the window is made smaller
    grid-template-columns: 0 minmax(0, auto);
  }

  rcp-top-nav {
    grid-area: nav;
    height: var(--legacy-navbar-height);
  }

  rcp-side-nav {
    grid-area: side;
    width: var(--legacy-navbar-width);
    transition: width 0.3s;

    &.sidebar-expanded {
      width: var(--legacy-navbar-width-expanded);
    }
  }

  .main-outlet-container {
    grid-area: main;
  }
}

.view-container {
  display: flex;
  flex-grow: 1;

  .view-sidebar {
    // we also use view-main in modal windows, but there we don't want to set the height
    &:not(rcp-modal-window .view-sidebar) {
      // needs a height property for scrolling
      height: calc(100vh - var(--legacy-navbar-height));
    }

    // bottom: 0;
    width: var(--legacy-navbar-secondary-width);
    overflow-y: auto;

    background-color: var(--col-bg);
    border-right: 1px solid var(--legacy-col-lines);
    flex-shrink: 0;
    z-index: 0;

    h1,
    h2,
    h4 {
      padding: var(--legacy-sidebar-padding);
      padding-top: var(--legacy-default-padding);
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .view-sidebar-header-container {
      padding: var(--legacy-sidebar-padding);
      padding-top: var(--legacy-default-padding);

      h1 {
        padding: 0;
      }
    }

    h1 + h4 {
      padding-top: 0;
      margin-top: -10px;
    }

    &.less-wide {
      width: 240px;
    }
  }

  .view-main {
    flex-grow: 1;
    z-index: 0;
    overflow-y: auto;
    background-color: white;
    position: relative;

    // we also use view-main in modal windows, but there we don't want to set the height
    &:not(rcp-modal-window .view-main) {
      // needs a height property for scrolling, minus the navbar-height for spacing
      height: calc(100vh - var(--legacy-navbar-height));
    }

    // overflow is necessary to limit total width to 100% (chrome)
    &:not(.overflow-visible) {
      overflow-x: hidden;
    }

    .fold {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    // inset view specially for summary
    .view-main-summary {
      border-radius: 2px;
      background-color: white;
      position: relative;
      z-index: 1;

      @include media-medium-up {
        margin: 30px;
      }
    }
  }
}

// view-sidebar directly after fold, undo margin
.fold + .view-container {
  margin-top: calc((var(--legacy-navbar-height) - 30px) * -1);
  margin-top: calc((var(--legacy-navbar-height) - 30px) * -1);
}

rcp-modal-window {
  .view-container {
    height: auto;
  }

  .content-main.scrolling-sidebar {
    height: 100%;
    border-top: 1px solid var(--legacy-col-lines);
    .view-container {
      height: 100%;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-0.5 * var(--legacy-default-padding));
  margin-right: calc(-0.5 * var(--legacy-default-padding));
  margin-bottom: calc(-1 * var(--legacy-default-padding)) !important;

  .col {
    flex: 1 0 auto;
    padding-left: var(--legacy-default-padding-s);
    padding-right: var(--legacy-default-padding-s);
    padding-bottom: var(--legacy-default-padding);

    // note: min-width should at least be set!
    // min-width: 0; //collapsible child text
    min-width: 400px;

    &.fb-50 {
      flex-basis: 50%;
    }

    &.fb-30 {
      flex-basis: 30%;
    }
  }

  .col-grow-0 {
    flex-grow: 0;
  }
}

// Exceptions in sidebar
.as-sidebar .row .col-align-right {
  //always center text in sidebar instead of right
  text-align: center;
}

.guidelines-box {
  border: 1px solid var(--legacy-col-lines);
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  > * {
    flex-grow: 1;
  }
}

// big score container
.col.score-container {
  h3 {
    font-size: 1.2em;
    margin-bottom: var(--legacy-default-element-spacing);
  }
  h4 {
    font-size: 0.8em;
    margin-bottom: var(--legacy-default-element-spacing);
  }
  .badge.huge {
    margin: 0;
    margin-bottom: var(--legacy-default-element-spacing);
  }
}

// youtube or other embeds
.iframe-wrapper {
  border: 1px solid var(--legacy-col-lines);
  margin: var(--legacy-default-spacing) auto;
  background-color: var(--legacy-col-lines);
  border-radius: var(--legacy-br);
  overflow: hidden;
  width: 80%;

  .video-wrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .caption {
    color: var(--col-text-secondary);
    text-align: center;
    font-size: smaller;
    // font-style: italic;
    padding: 5px;
  }
}

// fill screen with iframe (used for embedded apps)
iframe.iframe-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
}

// helper classes
.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.block {
  display: block;
}
.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

[mb-2],
table.table[mb-2] {
  margin-bottom: calc(2 * var(--legacy-default-element-spacing)) !important;
}

[mb-10] {
  margin-bottom: calc(10 * var(--legacy-default-element-spacing)) !important;
}

[mb-0] {
  margin-bottom: 0 !important;
}

[mb-1] {
  margin-bottom: var(--legacy-default-element-spacing) !important;
}

[mb-3] {
  margin-bottom: calc(2 * var(--legacy-default-element-spacing)) !important;
}

[mb-4] {
  margin-bottom: calc(3 * var(--legacy-default-element-spacing)) !important;
}

.padding {
  padding: var(--legacy-default-padding);
}

.padding-top {
  padding-top: var(--legacy-default-padding);
}

.padding-v {
  padding: var(--legacy-default-padding) 0;
}

.padding-h {
  padding: 0 var(--legacy-default-padding);
}

// may be used to pad an action link below an input field
.input-like-padding {
  padding: 10px 15px;
}

.pull-right {
  margin-left: auto;
  float: right;
}

.pull-left {
  float: left;
}

.margin-top,
.mt {
  margin-top: var(--legacy-default-element-spacing);
}

.margin-bottom,
.mb {
  margin-bottom: var(--legacy-default-element-spacing);
}

.margin-left,
.ml {
  margin-left: var(--legacy-default-element-spacing-s);
}

.margin-right,
.mr {
  margin-right: var(--legacy-default-element-spacing-s);
}

.overflow-visible {
  overflow: visible !important;
}

.visible-if-last:not(:last-child) {
  display: none;
}

.box-border {
  display: block;
  border: 1px solid var(--legacy-col-lines) !important;
  border-radius: var(--legacy-br);
  overflow: hidden;
  position: relative;

  &.box-border-padding {
    padding: 15px;
  }

  &.box-border-flex {
    display: flex;

    .col-content {
      flex-grow: 1;
    }

    .col-user {
      rcp-avatar {
        margin-right: 15px;
      }
    }
  }
}

.form-container {
  // padding: var(--legacy-default-padding);
  padding: 10px;
  background: var(--legacy-col-bg-main);

  .form-content {
    background: white;
    border-radius: var(--legacy-br);
    @include sharp-shadow();
  }
}

// general purpose responsive grid
.responsive-grid {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  @include responsive-grid();
}

.no-margin {
  margin: 0;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

rcp-side-nav.sidebar-expanded {
  .nav-sub-header {
    opacity: 1;
  }
}

.filter-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--legacy-default-element-spacing);
  gap: var(--legacy-default-element-spacing);
}

// this style is used in reusable components that project ng-content
// because of view encapsulation, it's impossible to style the projected content without using
// the deprecated ng-content otherwise.
// the style is placed on the element that contains the <ng-content> tag
.global-component-style-flex-gap {
  > * {
    display: flex;
    align-items: center;
    gap: var(--legacy-button-spacing);
  }
}

.global-component-style-flex-gap-inline {
  > * {
    display: inline-flex;
    align-items: center;
    gap: var(--item-internal-spacing-md);
  }
}

// TO DO
// included only a subset of new layout styles
// that are already used in skill-fold
// other styles should be added in additional PR

.page-header {
  display: flex;
  align-items: flex-start;
  h1,
  h2,
  p {
    margin: 0;
  }
  p {
    margin-top: 6px;
  }

  [actions] {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.atom-container-h,
.button-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  > * {
    min-width: 0; // prevent growing outside container
  }

  &.center {
    justify-content: center;
  }
}

.section-container {
  // when spacing elements, unset some default vertical margins to prevent double spacing
  > p,
  > rcp-section-filter-bar,
  > h4 {
    margin: 0;
  }

  // all children stretch to full width by default, but there are some items that should not, such as buttons
  > rcp-button,
  > .button {
    align-self: start;
  }

  // if a child element is empty, it can still affect the layout because of the 'gap' property
  // position it absolutely to prevent this (could also use display:none, but that might cause side effects if there are elements that are supposed to be visible)
  > div:empty,
  > p:empty,
  > span:empty,
  > router-outlet:empty {
    position: absolute;
  }
}

.atom-spacing-h {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}
