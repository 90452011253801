@mixin lightest-shadow() {
  box-shadow: 0 4px 12px 0 rgba(black, 0.06);
}

@mixin light-shadow() {
  box-shadow: 0 2px 4px 0 rgba(black, 0.05);
}

// this trick animates a shadow much smoother than actually animating the box-shadow property
@mixin light-shadow-on-hover() {
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0 2px 4px 0 rgba(black, 0.05);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    // in safari, tr:after does not work so it makes this element huge. pointer-events: none makes sure all other items are still clickable
    pointer-events: none;
  }
  &:hover:after {
    opacity: 1;
  }
}

@mixin sharp-shadow() {
  box-shadow: 0 1px 1px 0 rgba(black, 0.1);
}

@mixin shadow() {
  box-shadow: 0 2px 6px 0 rgba(black, 0.15);
}

@mixin dark-shadow() {
  box-shadow: 0 2px 6px 0 rgba(black, 0.2);
}

@mixin fade-out($color: white, $degrees: 0deg, $startAt: 0%, $endAt: 50%) {
  background: repeating-linear-gradient(
    $degrees,
    $color 0%,
    $color $startAt,
    rgba($color, 0) $endAt,
    rgba($color, 0) 100%
  );
}
