// New colors
// match color scheme in upcoming redesign
$gray-200: #e2e8f0;
$blue-0: #05003f;

// backup scss colors for mixinx
$legacy-blue-d1: #33658a;
$legacy-blue: #007bc0;
$legacy-blue-l1: #b8e5ff;
$legacy-black-l1: #2d2d2a;
$legacy-light-gray-l2: #fafbfb;
$legacy-green: #00a676;
$legacy-purple: #54288b;
$legacy-red: #f06543;
$legacy-sky-blue: #27baff;
$legacy-yellow: #fcba04;
$legacy-orange: #f09943;
$legacy-gaia: #00bcbd;
$legacy-col-primary: $legacy-blue;
$legacy-col-active: $legacy-blue;
$legacy-col-positive: $legacy-green;
$legacy-col-progress: $legacy-yellow;
$legacy-col-negative: $legacy-red;
$legacy-col-lines: $gray-200;
$col-text-primary: $blue-0;
$col-text-secondary: rgba($col-text-primary, 75%);
