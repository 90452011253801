@use 'mixins' as *;

table {
  width: 100%;

  &.table {
    &.compacter {
      width: 50%;

      td,
      th {
        height: var(--legacy-default-element-spacing);
        padding: 5px;
      }
    }

    &.no-lines {
      th,
      td {
        border-bottom: 0 !important;
      }
    }
  }

  // td width to set on cells
  td {
    &.w-50 {
      width: 50%;
    }

    &.w-40 {
      width: 40%;
    }

    &.w-5 {
      width: 5%;
    }
  }
}

.table-container-horizontal-scroll {
  width: 100%;
  overflow-x: auto;
}

.table-filter-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  // align last child to the right, even if it's the only one
  > :last-child {
    margin-left: auto;
  }
}

.table-container {
  display: inline-block;
  width: 100%;

  .nav.nav-tabs {
    margin-bottom: 0 !important;
  }

  .table {
    margin-top: -1px;
    width: 100%;
  }

  &.table-container-spreadsheet {
    position: relative;
    display: block;
    width: auto;
    overflow-x: auto;
  }
}

.table-container-buttons {
  position: relative;
  z-index: 1;

  .table-container-scroll-button {
    user-select: none;
    position: absolute;
    z-index: 1;
    padding: 22px;
    width: 250px;
    font-size: larger;
    cursor: pointer;

    color: var(--col-primary-dark);
    &:hover {
      color: var(--legacy-col-primary);
    }

    opacity: 0;
    pointer-events: none;
    transition:
      opacity 0.2s,
      color 0.3s;

    &.visible {
      pointer-events: initial;
      opacity: 1;
    }

    &.table-container-scroll-button-left {
      left: 0;
      @include fade-out(white, 90deg, 50%, 100%);
    }
    &.table-container-scroll-button-right {
      right: 0;
      text-align: right;
      @include fade-out(white, 270deg, 50%, 100%);
    }
  }
}

table {
  &.table {
    clear: both;
    margin-bottom: 0;
    border-spacing: 0;

    // fix z-index issue with dropdown
    position: relative;
    z-index: 0;

    &.compact {
      td,
      th {
        height: var(--legacy-default-element-spacing);
      }

      &.center {
        td,
        th {
          text-align: center;
        }
      }
    }

    // table in table
    table {
      margin-top: -1px;
    }

    .table-date-col {
      min-width: 80px;
    }

    tbody.striped:nth-child(even) {
      background-color: var(--legacy-light-gray-l2);
    }

    tr,
    tbody {
      width: 100%;
      outline: none;

      // rows
      &.clickable {
        position: relative;
        cursor: pointer;
        @include light-shadow-on-hover;
        & {
          td {
            transition:
              0.5s background-color,
              0.5s color;
          }

          &:hover:not(.disabled) {
            z-index: 1;

            td {
              color: var(--legacy-col-active);
            }
          }
        }
      }

      &.disabled {
        cursor: not-allowed;
        td {
          color: var(--col-text-muted);
        }
      }

      &.strike {
        td {
          color: var(--col-text-muted);
          text-decoration: line-through;
          .text {
            color: var(--col-text-muted);
          }
        }
      }

      &.highlight-info {
        border-left: 3px solid var(--legacy-col-primary);
      }

      &.highlight-progress {
        border-left: 3px solid var(--legacy-col-progress);
      }

      &.highlight-positive {
        border-left: 3px solid var(--legacy-col-positive);
      }

      &.highlight-negative {
        border-left: 3px solid var(--legacy-col-negative);
      }

      &.highlight-neutral {
        border-left: 3px solid var(--col-neutral);
      }

      &.selected {
        td {
          background-color: var(--col-active-bg);
        }
      }

      &.tr-align-top {
        td {
          vertical-align: top;
        }
      }

      &.tr-padding-small {
        td {
          padding: 5px;
        }
      }

      td.td-row-name {
        vertical-align: middle;
        white-space: normal;
      }
      td.td-canmed-score {
        max-width: 100px;
        .badge {
          margin-right: 0;
          top: 0;
        }
      }
    }

    // table head
    tr.table-head {
      .badge {
        margin: 10px 0;
      }

      td {
        border-top: none;
      }

      td.td-canmed-score {
        position: relative;
        overflow: visible;
        padding-top: 120px;
        span.column-name {
          white-space: normal;
          position: absolute;
          top: 80px;
          transform-origin: 0 50%;
          line-height: 20px;
          transform: rotate(-45deg);
        }
        span.score-n {
          padding-top: 5px;
          font-size: smaller;
        }
      }
    }

    // cells
    td,
    th {
      border-bottom: 1px solid var(--legacy-col-lines);
      vertical-align: middle;
      white-space: nowrap;

      text-overflow: ellipsis;
      height: var(--legacy-item-height);

      padding: var(--legacy-table-cell-padding);
      min-height: var(--legacy-item-height);

      &:not(.overflow-visible) {
        overflow: hidden;
      }

      &.no-padding {
        padding: 0;
      }

      &.multiline {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &.pre {
        // process new lines and <br>, and break on spaces to fit text to container
        white-space: pre-wrap;
      }

      &.size-overflow {
        max-width: 0;
        width: 100%;
      }
      &.wrap {
        white-space: normal;
        max-width: 1px;
      }

      // try to take as much width as possible
      &.expand {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 1px;
        width: 100%;
      }

      // try to take as little width as possible
      &.collapse {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 1px;
      }

      &.icon-only {
        padding-right: 0;
      }

      // avatar
      rcp-avatar {
        margin-right: 5px;
        vertical-align: 1px;
      }
      rcp-user-avatar rcp-avatar {
        margin-right: 0; // rcp-user-avatar does its own spacing
      }

      &.truncate-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 1px;
        width: 100%;
      }
    }

    th {
      font-weight: normal;
      text-align: left;
      &.right {
        text-align: right;
      }
      color: var(--col-text-tertiary);
      font-weight: bold;

      &.clickable {
        cursor: pointer;
        transition:
          0.5s background-color,
          0.5s color;

        &.active {
          border-bottom: 2px solid var(--legacy-col-active);
          background-color: var(--col-active-bg);
        }

        &:hover {
          background-color: var(--col-active-bg);
          color: var(--legacy-col-active);
        }
      }
    }

    td.accent,
    td .accent {
      color: var(--col-accent);
    }

    td.strong {
      font-weight: 600;
    }

    .button,
    .buttons,
    input,
    [dropdown] {
      margin-bottom: 0;
    }

    // table loader animation
    .is-loading {
      position: relative;
      display: inline-block;
      overflow: hidden;
      vertical-align: top;
      width: 100%;

      // IE fix for 100% width because of inline-block
      tbody,
      tr,
      td {
        display: block;
        width: 100%;
      }

      &:after {
        animation-name: line-moving;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 2px;
        width: 100%;
        background-color: var(--col-accent);
      }

      tr[portfolio-forms-table-row] {
        display: none;
      }
    }

    tr.empty-table-message {
      td {
        padding: 20px;
        button {
          margin-top: 15px;
        }
      }
    }
  }

  &.table-pull {
    td,
    th {
      // making the last td 100% wide will push others to the left
      // if there are (multiple) td's with class align-right
      // then the first one should function as the full width one
      // so we set all of them 100%
      &.align-right,
      &:last-child:not(:first-child) {
        padding-right: 15px;
        text-align: right;
        width: 100%;
      }
      // and then undo all after the first one
      &.align-right ~ .align-right {
        width: auto;
        // these also get a wider left padding
        padding-left: 40px;
      }
    }
  }

  // Spreadsheet table styling
  &.table-spreadsheet {
    tr {
      transition:
        background-color 0.15s,
        opacity 0.15s;
    }

    td {
      position: relative;
      overflow: visible;
      input-toggle {
        display: inline-block;
      }
    }

    td:not(:first-of-type) {
      min-width: 250px;
    }

    // successful row
    tr.success {
      color: var(--legacy-col-positive);
      background-color: var(--col-positive-bg);

      input {
        pointer-events: none;
        color: var(--legacy-col-positive);
        background-color: rgba(white, 0.5);
      }

      rcp-input-select-dropdown,
      input-toggle {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    //failed row
    tr.error {
      color: var(--legacy-col-negative);
      background-color: var(--col-negative-bg);

      input {
        border: 1px solid var(--legacy-col-negative);
        background-color: rgba(white, 0.5);
      }
    }

    //alerted row
    tr.alert {
      color: var(--col-alert);
      background-color: var(--col-alert-bg);

      input {
        border: 1px solid var(--col-alert);
        background-color: rgba(white, 0.5);
      }
    }

    // Muted row
    tr.muted {
      background-color: var(--legacy-light-gray-l2);
      opacity: 0.5;
      &.disabled {
        pointer-events: none;
      }
    }

    // mimic excel spreadsheet view
    tr.row-spreadsheet {
      td {
        padding: 0;
        min-height: 0;
        height: 1em;
        border: none !important;

        &.td-status {
          padding: 0 15px;
        }

        .spreadsheet-cell {
          width: 100%;
          height: 100%;
          border: 1px solid var(--legacy-col-lines);
          vertical-align: middle;
          line-height: 1.8em;
          min-height: 28px;

          &.selected {
            border-color: var(--legacy-col-primary);
            background: var(--col-primary-bg);
          }

          &.valid {
            background: var(--col-positive-bg);
          }

          &.invalid {
            border-color: var(--legacy-col-negative);
            background: var(--col-negative-bg);
          }

          input {
            padding: 5px;
            min-height: 0;
            background: transparent !important;
          }
        }
      }
    }
  }
}
