/*
* Styling for printable elements such as forms
**/

.print-value {
  display: none;
}

.page-break {
  page-break-before: always;
}

.page-break-avoid {
  page-break-inside: avoid;
}

.page-break-after {
  page-break-after: always;
}

@page {
  size: a4 portrait;
  margin: 10mm;
}

@media print {
  .no-print {
    display: none;
  }

  .print-value {
    display: block !important;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 99%;
    color: black !important;
    // needs to be visible so we can print all the page
    overflow: visible !important;
  }

  table {
    th {
      text-align: left;
    }
  }

  rcp-root {
    visibility: hidden;
  }

  main {
    // unset absolute or it will not 'scroll'
    position: initial;
    height: auto;

    rcp-top-nav,
    rcp-side-nav {
      width: 0 !important;
      height: 0 !important;
    }

    // the printable class on main controls if the main view will be printed (otherwise an overlay like modal might be printed)
    &.printable {
      .view-container {
        // unset absolute or it will not 'scroll'
        position: initial;
        max-width: 100vw;
        .view-main {
          visibility: visible;
        }
      }
    }
  }

  rcp-modal-container {
    // unset 'fixed' or the full page will not be printed
    position: initial !important;
  }

  rcp-modal-window {
    visibility: visible;
    display: initial !important;
    position: initial !important;
    .overlay {
      display: none !important;
    }
    .container {
      display: block !important;
      width: 100vw !important;
      height: auto !important;
      overflow: auto !important;

      .content-container {
        display: block !important;
        max-height: none !important;
        height: auto !important;

        .content-main {
          display: block !important;
        }
      }
    }
    .view-container {
      display: block !important;
      .view-sidebar {
        visibility: hidden;
      }
      .view-main {
        visibility: visible;
        display: block !important;
      }
    }
  }

  * {
    border: 0 !important; // no borders for anything
    border-color: #fff;
  }

  // summary page specific
  .fold.fold-summary {
    .background {
      background-color: transparent !important;
      background-image: none !important;
      .button {
        display: none !important;
      }
    }
    &.has-photo .avatar-container .avatar-labels {
      h1,
      h2,
      a {
        color: initial !important;
        text-shadow: none !important;
      }
    }
  }

  // visual elements that are only defined by a background color don't get printed by default
  // so we add a border
  .badge {
    border: 1px solid var(--legacy-col-lines) !important;

    &.progress {
      border: 1px solid var(--legacy-col-progress) !important;
      color: var(--legacy-col-progress) !important;
      background-color: transparent;
    }
    &.positive {
      border: 1px solid var(--legacy-col-positive) !important;
      color: var(--legacy-col-positive) !important;
      background-color: transparent;
    }
    &.negative {
      border: 1px solid var(--legacy-col-negative) !important;
      color: var(--legacy-col-negative) !important;
      background-color: transparent;
    }
  }

  .rcp-label {
    box-shadow: none !important;
  }
  .filter-label-container {
    flex-direction: column !important;
    .rcp-label {
      color: var(--col-neutral) !important;
    }
  }

  .timeline-padded-container {
    border: 1px solid var(--legacy-col-lines) !important;
    .timeline-period {
      border: 1px solid var(--col-neutral) !important;
      background-color: transparent;
      color: var(--col-text-primary) !important;
    }
    .marker .text {
      color: var(--col-text-primary) !important;
    }
  }

  // some elements are just not relevant for printing
  rcp-docs-icon,
  rcp-visibility-tooltip,
  action-summary,
  rcp-app-loading,
  .button {
    display: none !important;
  }

  // more compact view, set background to white
  .form-container {
    padding: var(--legacy-default-element-spacing) !important;
    background: #ffffff !important;

    .form-content {
      border: 0;
      border-color: #fff;
      box-shadow: unset !important;

      // make the content a bit more compact
      section:not(.no-padding) {
        padding: 10px 10px !important;
        border: 0;

        header {
          margin-bottom: 15px !important;
        }
      }

      section::after {
        content: '';
        border-bottom: 0;
      }
    }
  }

  // unstyle any links
  a {
    text-decoration: none;
    color: inherit !important;
  }

  .show-small {
    // show text instead of selection buttons
    display: block;
  }

  .no-print,
  portfolio-document-form > portfolio-document-status, // do not show status approved / concept etc in the top
  portfolio-document-status, // do not show status approved / concept in the bottom
  .buttons-container, // no button container for yes/no selection options, instead show element with class .show-small
  .buttons, // used in the modal-title, close & full size
  .view-sidebar, //no sidebar with different views of the form
  topbar, // no topbar navigation
  rcp-user-avatar, // no image for the users
  .form-description // only helps if there is something to do
  {
    display: none !important;
  }

  // shown normally as a badge, with centered text.
  .option-label {
    background-color: white !important;
    text-align: initial !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  p.info-box {
    background: none !important;
    padding: 0 !important;
  }

  goal-status-label {
    .rcp-label {
      background: white !important;
      color: inherit !important;
    }

    fa-icon {
      display: none !important;
    }
  }

  // show labels as normal rem
  .rcp-label {
    font-size: 1rem !important;
    display: block !important;
    padding: 0;
  }
}
