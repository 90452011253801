@keyframes line-moving {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  40% {
    transform: translate3d(0.01%, 0, 0);
  }
  60% {
    transform: translate3d(0.01%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.loading {
  position: fixed;
  // top: 50%;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: var(--col-accent);

  // &:after {
  //   animation-name: line-moving;
  //   animation-duration: 3s;
  //   animation-iteration-count: infinite;
  //   content: "";
  //   display: block;
  //   position: fixed;
  //   top: 0;
  //   height: 2px;
  //   width: 100%;
  //   background-color: var(--col-accent);
  // }

  svg {
    position: absolute;
    top: 50%;
    width: 50px;
    stroke: white;
  }

  // .loading-text {
  //   position: relative;
  // }
}
