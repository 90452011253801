/* These color variables match the
 updated color scheme in PortfolioApp2020.sketch file */

:root {
  --legacy-blue-d1: #173eab;
  --legacy-blue: #1d4ed8;
  --legacy-blue-l1: #b8e5ff;
  --legacy-blue-l2: #e5f6ff;
  --legacy-blue-l3: #fafdff;
  --legacy-blue-l4: #eef4f8;

  --legacy-black-l1: #2d2d2a;
  --legacy-black-l2: #4a4a4a;

  --legacy-gray: #5d6c7f;
  --legacy-light-gray: #dbdbdb;
  --legacy-light-gray-l1: #e8e9eb;

  --legacy-light-gray-l2: #fafbfb;
  --legacy-light-gray-l3: #fcfdfd;

  --legacy-green-d1: #007c58;
  --legacy-green: #00a676;
  --legacy-green-l1: #c9eee4;
  --legacy-green-l2: #ecf9f5;

  --legacy-purple: #54288b;
  --legacy-purple-l1: #d9c8ef;
  --legacy-purple-l2: #f1ebf9;

  --legacy-red-d1: #ad3b1f;
  --legacy-red: #f06543;
  --legacy-red-l1: #f9c9bd;
  --legacy-red-l2: #fdece8;

  --legacy-sky-blue: #27baff;
  --legacy-sky-blue-l1: #b8e8ff;
  --legacy-sky-blue-l2: #e5f7ff;

  --legacy-yellow-d1: #a07500;
  --legacy-yellow: #fcba04;
  --legacy-yellow-l1: #feecb8;
  --legacy-yellow-l2: #fff8e6;

  --legacy-orange-d1: #c2732a;
  --legacy-orange: #f09943;
  --legacy-orange-l1: #fad3af;
  --legacy-orange-l2: #f2e1d2;

  --legacy-purple: #54288b;
  --legacy-gaia: #00bcbd;

  /*New colors*/
  /*match color scheme in upcoming redesign*/
  --gray-200: #e2e8f0;
  --blue-0: #05003f;

  /*main background color*/
  --legacy-col-bg-main: #f7fafc;

  --legacy-col-primary: var(--legacy-blue);
  --legacy-col-active: var(--legacy-blue);
  --legacy-col-positive: var(--legacy-green);
  --legacy-col-progress: var(--legacy-yellow);
  --legacy-col-negative: var(--legacy-red);

  --legacy-col-bg-darkest: var(--legacy-black-l1);
  --legacy-col-lines: var(--gray-200);
  --legacy-col-bg-info-box: var(--gray-200);
  --legacy-col-bg: var(--legacy-light-gray-l2);

  --col-primary-dark: var(--legacy-blue-d1);
  --col-primary: var(--legacy-blue);
  --col-primary-bg: var(--legacy-blue-l1);

  --col-active-dark: var(--legacy-blue-d1);
  --col-active: var(--legacy-blue);
  --col-active-bg: var(--legacy-blue-l2);

  --col-accent: var(--legacy-sky-blue);
  --col-accent-bg: var(--legacy-sky-blue-l1);
  --col-accent-light-bg: var(--legacy-sky-blue-l2);

  --col-positive-dark: var(--legacy-green-d1);
  --col-positive: var(--legacy-green);
  --col-positive-bg: var(--legacy-green-l1);

  --col-alert-dark: var(--legacy-yellow-d1);
  --col-alert: var(--legacy-yellow);
  --col-alert-bg: var(--legacy-yellow-l1);

  /*progress is a separate variable, since in the future it may change from the alert color*/
  --col-progress: var(--legacy-yellow);

  --col-negative-dark: var(--legacy-red-d1);
  --col-negative: var(--legacy-red);
  --col-negative-bg: var(--legacy-red-l1);

  --col-neutral: var(--legacy-gray);

  --col-bg-darkest: var(--legacy-black-l1);
  --col-bg-darker: var(--legacy-black-l2);
  --col-bg: var(--legacy-light-gray-l2);
  --col-bg-lightest: var(--legacy-light-gray-l3);
  --col-tooltip-bg: var(--col-bg-darkest);

  --col-item-icons: var(--legacy-light-gray-l1);
  --col-inactive: var(--legacy-light-gray-l1);

  --col-text-titles: var(--legacy-black-l1);
  --col-text-headers: var(--blue-0);

  --col-text-primary: var(--blue-0);
  --col-text-secondary: rgba(from var(--col-text-primary) r g b / 0.75);
  --col-text-tertiary: rgba(from var(--col-text-primary) r g b / 0.5);
  --col-text-muted: rgba(from black r g b / 0.4);

  --legacy-col-lines: var(--gray-200);

  --black-l1: #121416;
  --black-l1-0-for-gradient: #12141600;
  --black-l2: #1b1d21;
  --black-l3: #2b2e34;
  --gray: #5d6c7f;
  --gray-l1: #e2e8f0;
  --gray-l2: #e8e9eb;
  --gray-l3: #f0f6f9;
  --gray-l3-0-for-gradient: #f0f6f900;
  --gray-hard-d2: #353535;
  --gray-hard-d3: #5d5d5d;
  --gray-hard: #bcbcbc;
  --gray-hard-l1: #f4f4f4;
  --white: #fff;
  --white-a10: #ffffff1a;
  --white-a50: #ffffff80;
  --white-a70: #ffffffb2;
  --white-a90: #ffffffe5;
  --blue: #1d4ed8;
  --blue-2: #173eab;
  --blue-d1: #05003f;
  --blue-d1-a70: #05003fb2;
  --blue-d1-a50: #05003f80;
  --blue-d1-a40: #05003f66;
  --blue-l0: #a5b9f3;
  --blue-l1: #e5f6ff;
  --green: #00a676;
  --green-2: #008f65;
  --green-d1: #007c58;
  --green-l0: #8bdac5;
  --green-l1: #c9eee4;
  --orange: #f09943;
  --orange-d1: #bd660f;
  --orange-l0: #f7b87d;
  --orange-l1: #fad3af;
  --red: #f06543;
  --red-2: #ee512a;
  --red-d1: #ad3b1f;
  --red-l0: #f5a38f;
  --red-l1: #f9c9bd;
  --yellow: #fcba04;
  --yellow-2: #e3a703;
  --yellow-d1: #a07500;
  --yellow-l0: #fdd04f;
  --yellow-l1: #feecb8;

  /* light scheme */
  --col-bg-base: var(--white);
  --col-bg-main: var(--gray-l3);
  --col-bg-main-0-for-gradient: var(--gray-l3-0-for-gradient);
  --col-lines: var(--gray-l1);
  --col-list-item-active: var(--gray-l3);
  --col-neutral-bg: var(--gray-l1);
  --col-tooltip-bg: var(--blue);
  --col-tooltip-text: var(--white);
  --col-main-nav-bg-base: var(--black-l2);
  --col-main-nav-bg-main: var(--black-l1);
  --col-main-nav-hover: var(--white-a10);
  --col-main-nav-lines: var(--black-l3);
  --col-text-main-nav: var(--white-a70);
  --col-text-main-nav-hover: var(--white);
  --col-text-main-nav-icon: var(--white-a70);
  --col-alert: var(--yellow);
  --col-alert-hover: var(--yellow-2);
  --col-alert-lines: var(--yellow-l0);
  --col-alert-bg: var(--yellow-l1);
  --col-alert-dark: var(--yellow-d1);
  --col-disabled-base: var(--gray-hard-l1);
  --col-disabled-contrast: var(--gray-hard);
  --col-negative: var(--red);
  --col-negative-hover: var(--red-2);
  --col-negative-lines: var(--red-l0);
  --col-negative-bg: var(--red-l1);
  --col-negative-dark: var(--red-d1);
  --col-severe: var(--orange);
  --col-severe-dark: var(--orange-d1);
  --col-severe-lines: var(--orange-l0);
  --col-severe-bg: var(--orange-l1);
  --col-positive: var(--green);
  --col-positive-hover: var(--green-2);
  --col-positive-lines: var(--green-l0);
  --col-positive-bg: var(--green-l1);
  --col-positive-dark: var(--green-d1);
  --col-active: var(--blue);
  --col-active-text: var(--blue);
  --col-active-focus-text: var(--blue-2);
  --col-active-bg: var(--blue-l1);
  --col-active-focus: var(--blue-2);
  --col-active-lines: var(--blue-l0);
  --col-primary: var(--blue);
  --col-primary-bg: var(--blue-l1);
  --col-primary-hover: var(--blue-2);
  --col-primary-dark: var(--blue-d1);
  --col-text-guaranteed-on-dark: var(--white);
  --col-text-secondary-guaranteed-on-dark: var(--white-a70);
  --col-text-guaranteed-on-light: var(--blue-d1);
  --col-text-secondary-guaranteed-on-light: var(--blue-d1-a70);
  --col-text-headers: var(--blue-d1);
  --col-text-primary: var(--blue-d1);
  --col-text-secondary: var(--blue-d1-a70);
  --col-text-tertiary: var(--blue-d1-a50);
  --col-progress: var(--yellow);
  --col-progress-hover: var(--yellow-2);
  --col-progress-lines: var(--yellow-l0);
  --col-progress-bg: var(--yellow-l1);
  --col-progress-dark: var(--yellow-d1);
  /* end light scheme */
}
