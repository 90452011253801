@use 'mixins' as *;

$tile-padding: 15px;

.tile-container {
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1 0 auto;
  }

  &.tile-container-nowrap {
    flex-wrap: nowrap;
    // make horizontally scrollable
    overflow-x: auto;
  }

  &.horizontal-scroll {
    overflow-x: scroll;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.responsive {
    gap: $tile-padding;
    > * {
      flex-basis: 30%;
    }
  }
}

a.tile {
  text-decoration: none !important;
}

.tile {
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle; //how multiple tiles align if inline back to back (affected by content)
  position: relative;

  flex-grow: 1;

  &.w250 {
    max-width: 250px;
    min-width: 250px;
  }

  &.max-width {
    width: 100%;
  }

  &.full-width {
    margin-right: 0;
    margin-bottom: 0;
    display: flex;
  }

  border: 1px solid var(--legacy-col-lines);
  background-color: white;

  &.disabled {
    pointer-events: none;
    background-color: var(--col-bg);
    color: var(--col-text-muted);
    .tile-fold {
      background-color: var(--legacy-col-lines) !important;
    }
    &:hover {
      background-color: var(--col-bg);
    }
  }

  &.clickable,
  &.selectable {
    @include light-shadow-on-hover;
    & {
      transition: background-color 0.3s;

      &:hover,
      &.active {
        .tile-label {
          color: var(--legacy-col-primary);
        }
      }
      &.active {
        background-color: var(--legacy-col-active);
      }
    }
  }

  &.selectable {
    cursor: pointer;
    transition:
      transform 0.5s,
      background-color 0.3s,
      border-color 0.3s;

    .tile-fold {
      background-color: var(--col-bg);
      color: var(--legacy-col-lines);
      transition: background-color 0.3s;

      .check-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid var(--legacy-col-lines);
        transition:
          border-color 0.3s,
          color 0.3s;
      }
    }

    &:hover {
      @extend .selected;
    }
  }

  &.selected {
    background-color: var(--col-positive-bg);
    border-color: var(--legacy-col-positive);

    > * {
      color: var(--legacy-col-positive);
    }
    .muted,
    .secondary {
      color: rgba(var(--legacy-col-positive), 0.7) !important;
    }

    .tile-fold {
      background-color: var(--legacy-col-positive);
      .check-circle {
        border-color: rgba(white, 0.5);
        color: rgba(white, 0.5);
      }
    }
  }

  &.selected {
    .tile-fold {
      .check-circle {
        background-color: white;
        color: var(--legacy-col-positive);
      }
    }
  }

  // tile indicating something new can be created
  &.tile-new {
    border-style: dashed;
  }

  // slots
  > * {
    flex: 0 0 auto;
    padding: $tile-padding;
  }
  .flex-1 {
    flex: 1 0 auto;
  }
  .tile-label {
    flex: 1 1 auto;
    padding: $tile-padding;
    transition: color 0.3s;

    .tile-label-fold {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .tile-fold {
    flex-shrink: 0;
    padding: $tile-padding;
    background: var(--legacy-col-primary);
    color: white;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: var(--legacy-br);
    border-bottom-left-radius: var(--legacy-br);

    .tile-icon {
      font-size: 2em;
    }

    &.has-photo {
      text-shadow: 0px 1px 2px rgba(black, 0.5);
      background-size: cover;
    }
  }

  // remove bottom margins from button
  .button {
    margin-bottom: 0;
  }

  // specific for normal (horizontal) layout
  &:not(.tile-v) {
    .avatar-container {
      flex: 0 0 auto;
      padding: $tile-padding;
      padding-right: 0;

      > img {
        width: 80px;
        display: block;
      }
    }

    // ellipsis...
    .tile-label:not(.text-wrap) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  // specific for vertical layout
  &.tile-v {
    flex-direction: column;
    align-items: stretch; //span full width of tile
    &:not(.align-left) {
      text-align: center;
    }

    .tile-fold {
      background-size: cover;
      background-position: center;
      justify-content: center;

      border-top-left-radius: var(--legacy-br);
      border-top-right-radius: var(--legacy-br);
      border-bottom-left-radius: 0;

      .tile-icon {
        margin-bottom: -5px;
        margin-right: 0;
      }

      .tile-center-piece {
        width: 80px;
        height: 80px;
        border-radius: var(--legacy-br);
        background: white;
        color: var(--legacy-col-primary);
        font-size: 2em;

        // center any content
        display: inline-flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: center;
      }

      avatar,
      .tile-center-piece {
        @include sharp-shadow;
        & {
          margin-bottom: -20px; //pull fold back under centerpiece
        }
      }
    }

    .tile-label {
      flex-basis: 1em; //IE Fix; not ideal since we would like content to grow automatically even if it's multiline
    }
  }

  // tile colors
  // also used as automatic colors
  &.blue,
  &.color:nth-child(5n + 1) {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-blue);
      .tile-center-piece {
        color: var(--legacy-blue);
      }
    }
  }

  &.accent,
  &.sky-blue,
  &.secondary,
  &.color:nth-child(5n + 2) {
    color: inherit;
    .tile-fold {
      background-color: var(--col-accent);
      .tile-center-piece {
        color: var(--col-accent);
      }
    }
  }

  &.accent-full {
    background-color: var(--col-accent);
    color: white;
    .secondary {
      color: rgba(white, 0.7);
    }
  }

  &.positive,
  &.green,
  &.color:nth-child(5n + 3) {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-green);
      .tile-center-piece {
        color: var(--legacy-green);
      }
    }
  }

  &.no-weight {
    color: inherit;
    .tile-fold {
      background-color: var(--col-neutral);
      .tile-center-piece {
        color: var(--col-neutral);
      }
    }
  }

  &.orange,
  &.color:nth-child(5n + 3) {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-orange);
      .tile-center-piece {
        color: var(--legacy-orange);
      }
    }
  }

  &.progress,
  &.yellow,
  &.alert,
  &.color:nth-child(5n + 4) {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-yellow);
      .tile-center-piece {
        color: var(--legacy-yellow);
      }
    }
  }

  &.purple,
  &.color:nth-child(5n) {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-purple);
      .tile-center-piece {
        color: var(--legacy-purple);
      }
    }
  }

  // special colors
  &.negative,
  &.red {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-red);
      .tile-center-piece {
        color: var(--legacy-red);
      }
    }
  }

  &.gaia {
    color: inherit;
    .tile-fold {
      background-color: var(--legacy-gaia);
      .tile-center-piece {
        color: var(--legacy-gaia);
      }
    }
  }

  &.inactive {
    color: inherit;
    background-color: var(--col-bg);
    .tile-fold {
      background-color: var(--col-neutral);
      .tile-center-piece {
        color: var(--col-neutral);
      }
    }
  }
}
