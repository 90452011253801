.tippy-box[data-theme~='default'] {
  background-color: var(--col-tooltip-bg);
  color: var(--col-text-guaranteed-on-dark);
  max-width: var(--tooltip-max-width);

  border-radius: var(--border-radius-md);
  font-weight: var(--font-weight-normal);

  .tippy-arrow::before {
    color: var(--col-tooltip-bg);
  }

  .tippy-content {
    padding: var(--item-internal-spacing-md);
  }
}

.tippy-box[data-theme~='menu'] {
  background-color: var(--col-bg-base);
  color: var(--col-text-primary);
  max-width: var(--tooltip-max-width);

  border-radius: var(--border-radius-md);
  font-weight: var(--font-weight-normal);

  box-shadow: var(--effect-shadow-md);

  .tippy-content {
    /* overrides from tippy */
    font-size: var(--font-size-normal);
    padding: 0;
    white-space: nowrap;
  }
}

.tippy-box[data-theme~='popout-large'] {
  background-color: var(--col-bg-base);
  color: var(--col-text-primary);
  width: var(--dropout-feature-width);
  max-width: var(--dropout-feature-width);
  padding: var(--dropout-feature-padding);

  border: 1px solid var(--col-lines);
  border-radius: var(--border-radius-md);
  font-weight: var(--font-weight-normal);

  box-shadow: var(--effect-shadow-sm), var(--effect-shadow-md);

  .tippy-content {
    /* overrides from tippy */
    font-size: var(--font-size-normal);
    padding: 0;
    white-space: nowrap;
  }

  .tippy-arrow::before {
    color: var(--col-bg-base);
  }
}
